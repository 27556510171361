@font-face {
	font-family: 'PTSans';
	src: url('/fonts/PTSans/PTSans.eot');
	src: local('☺'), url('/fonts/PTSans/PTSans.woff') format('woff'), url('/fonts/PTSans/PTSans.ttf') format('truetype'), url('/fonts/PTSans/PTSans.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PTSansBold';
	src: url('/fonts/PTSans/PTSansBold.eot');
	src: local('☺'), url('/fonts/PTSans/PTSansBold.woff') format('woff'), url('/fonts/PTSans/PTSansBold.ttf') format('truetype'), url('/fonts/PTSans/PTSansBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PTSansBoldItalic';
	src: url('/fonts/PTSans/PTSansBoldItalic.eot');
	src: local('☺'), url('/fonts/PTSans/PTSansBoldItalic.woff') format('woff'), url('/fonts/PTSans/PTSansBoldItalic.ttf') format('truetype'), url('/fonts/PTSans/PTSansBoldItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PTSansCaption';
	src: url('/fonts/PTSans/PTSansCaption.eot');
	src: local('☺'), url('/fonts/PTSans/PTSansCaption.woff') format('woff'), url('/fonts/PTSans/PTSansCaption.ttf') format('truetype'), url('/fonts/PTSans/PTSansCaption.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PTSansCaptionBold';
	src: url('/fonts/PTSans/PTSansCaptionBold.eot');
	src: local('☺'), url('/fonts/PTSans/PTSansCaptionBold.woff') format('woff'), url('/fonts/PTSans/PTSansCaptionBold.ttf') format('truetype'), url('/fonts/PTSans/PTSansCaptionBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PTSansItalic';
	src: url('/fonts/PTSans/PTSansItalic.eot');
	src: local('☺'), url('/fonts/PTSans/PTSansItalic.woff') format('woff'), url('/fonts/PTSans/PTSansItalic.ttf') format('truetype'), url('/fonts/PTSans/PTSansItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PTSansNarrow';
	src: url('/fonts/PTSans/PTSansNarrow.eot');
	src: local('☺'), url('/fonts/PTSans/PTSansNarrow.woff') format('woff'), url('/fonts/PTSans/PTSansNarrow.ttf') format('truetype'), url('/fonts/PTSans/PTSansNarrow.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'PTSansNarrowBold';
	src: url('/fonts/PTSans/PTSansNarrowBold.eot');
	src: local('☺'), url('/fonts/PTSans/PTSansNarrowBold.woff') format('woff'), url('/fonts/PTSans/PTSansNarrowBold.ttf') format('truetype'), url('/fonts/PTSans/PTSansNarrowBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
