@font-face {
	font-family: 'CoreSansD15Thin';
	src: url('/fonts/CoreSansD/CoreSansD/CoreSansD/CoreSansD15Thin.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD15Thin.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD15Thin.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD15Thin.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD15Thin-Italic';
	src: url('/fonts/CoreSansD/CoreSansD15Thin-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD15Thin-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD15Thin-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD15Thin-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD17CnThin';
	src: url('/fonts/CoreSansD/CoreSansD17CnThin.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD17CnThin.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD17CnThin.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD17CnThin.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD17CnThin-Italic';
	src: url('/fonts/CoreSansD/CoreSansD17CnThin-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD17CnThin-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD17CnThin-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD17CnThin-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD25Light';
	src: url('/fonts/CoreSansD/CoreSansD25Light.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD25Light.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD25Light.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD25Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD25Light-Italic';
	src: url('/fonts/CoreSansD/CoreSansD25Light-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD25Light-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD25Light-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD25Light-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD27CnLight';
	src: url('/fonts/CoreSansD/CoreSansD27CnLight.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD27CnLight.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD27CnLight.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD27CnLight.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD27CnLight-Italic';
	src: url('/fonts/CoreSansD/CoreSansD27CnLight-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD27CnLight-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD27CnLight-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD27CnLight-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD35Regular';
	src: url('/fonts/CoreSansD/CoreSansD35Regular.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD35Regular.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD35Regular.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD35Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD35Regular-Italic';
	src: url('/fonts/CoreSansD/CoreSansD35Regular-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD35Regular-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD35Regular-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD35Regular-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD37CnRegular';
	src: url('/fonts/CoreSansD/CoreSansD37CnRegular.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD37CnRegular.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD37CnRegular.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD37CnRegular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD37CnRegular-Italic';
	src: url('/fonts/CoreSansD/CoreSansD37CnRegular-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD37CnRegular-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD37CnRegular-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD37CnRegular-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD45Medium';
	src: url('/fonts/CoreSansD/CoreSansD45Medium.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD45Medium.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD45Medium.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD45Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD45Medium-Italic';
	src: url('/fonts/CoreSansD/CoreSansD45Medium-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD45Medium-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD45Medium-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD45Medium-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD47CnMedium';
	src: url('/fonts/CoreSansD/CoreSansD47CnMedium.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD47CnMedium.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD47CnMedium.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD47CnMedium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD47CnMedium-Italic';
	src: url('/fonts/CoreSansD/CoreSansD47CnMedium-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD47CnMedium-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD47CnMedium-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD47CnMedium-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD55Bold';
	src: url('/fonts/CoreSansD/CoreSansD55Bold.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD55Bold.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD55Bold.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD55Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD55Bold-Italic';
	src: url('/fonts/CoreSansD/CoreSansD55Bold-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD55Bold-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD55Bold-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD55Bold-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD57CnBold';
	src: url('/fonts/CoreSansD/CoreSansD57CnBold.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD57CnBold.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD57CnBold.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD57CnBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD57CnBold-Italic';
	src: url('/fonts/CoreSansD/CoreSansD57CnBold-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD57CnBold-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD57CnBold-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD57CnBold-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD65Heavy';
	src: url('/fonts/CoreSansD/CoreSansD65Heavy.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD65Heavy.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD65Heavy.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD65Heavy.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD65Heavy-Italic';
	src: url('/fonts/CoreSansD/CoreSansD65Heavy-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD65Heavy-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD65Heavy-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD65Heavy-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD67CnHeavy';
	src: url('/fonts/CoreSansD/CoreSansD67CnHeavy.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD67CnHeavy.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD67CnHeavy.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD67CnHeavy.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD67CnHeavy-Italic';
	src: url('/fonts/CoreSansD/CoreSansD67CnHeavy-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD67CnHeavy-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD67CnHeavy-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD67CnHeavy-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD75Black';
	src: url('/fonts/CoreSansD/CoreSansD75Black.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD75Black.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD75Black.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD75Black.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD75Black-Italic';
	src: url('/fonts/CoreSansD/CoreSansD75Black-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD75Black-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD75Black-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD75Black-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD77CnBlack';
	src: url('/fonts/CoreSansD/CoreSansD77CnBlack.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD77CnBlack.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD77CnBlack.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD77CnBlack.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'CoreSansD77CnBlack-Italic';
	src: url('/fonts/CoreSansD/CoreSansD77CnBlack-Italic.eot');
	src: local('?'), url('/fonts/CoreSansD/CoreSansD77CnBlack-Italic.woff') format('woff'), url('/fonts/CoreSansD/CoreSansD77CnBlack-Italic.ttf') format('truetype'), url('/fonts/CoreSansD/CoreSansD77CnBlack-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}