@font-face {
	font-family: 'T-StarPro';
	src: url('/fonts/T-StarPro/T-StarPro.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-Bold';
	src: url('/fonts/T-StarPro/T-StarPro-Bold.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-Bold.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-Bold.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-BoldItalic';
	src: url('/fonts/T-StarPro/T-StarPro-BoldItalic.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-BoldItalic.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-BoldItalic.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-BoldItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-Heavy';
	src: url('/fonts/T-StarPro/T-StarPro-Heavy.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-Heavy.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-Heavy.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-Heavy.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-Italic';
	src: url('/fonts/T-StarPro/T-StarPro-Italic.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-Italic.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-Italic.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-Light';
	src: url('/fonts/T-StarPro/T-StarPro-Light.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-Light.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-Light.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-LightItalic';
	src: url('/fonts/T-StarPro/T-StarPro-LightItalic.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-LightItalic.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-LightItalic.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-LightItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-Medium';
	src: url('/fonts/T-StarPro/T-StarPro-Medium.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-Medium.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-Medium.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'T-StarPro-MediumItalic';
	src: url('/fonts/T-StarPro/T-StarPro-MediumItalic.eot');
	src: local('☺'), url('/fonts/T-StarPro/T-StarPro-MediumItalic.woff') format('woff'), url('/fonts/T-StarPro/T-StarPro-MediumItalic.ttf') format('truetype'), url('/fonts/T-StarPro/T-StarPro-MediumItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
